<template>
  <div class="flex flex-col w-full relative p-2 space-y-8">
    <div v-for="(d, index) in data" :key="`component-${index}`">
      <!-- v-bind="componentProps(d)" -->
      <component
        :is="d.component"
        :permission="d.permission || permission"
        :label="d.caption"
        :actions="d.actions"
        :columns="d.columns"
        :rows="d.rows"
        :show="d.show"
        :add-btn="d.addBtn"
        :icon="d.icon"
        :callback="d.callback"
        :resource="d.resource"
        :hide-category="d.hideCategory"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'repeater',
  props: ['data'],
  components: {},
  data() {
    return {
      permission: {
        module: '',
        subModule: '',
        childModule: '',
        operation: '',
        options: [],
      },
    }
  },
  methods: {},
}
</script>
